
import { defineComponent, inject, onMounted, onUnmounted, watch } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import IconoEnPreparacion from '@/views/Starkoms/Ordenes/IconoEnPreparacion.vue';
import IconoEntregado from '@/views/Starkoms/Ordenes/IconoEntregado.vue';
import IconoRetirado from '@/views/Starkoms/Ordenes/IconoRetirado.vue';
import IconoReparto from '@/views/Starkoms/Ordenes/IconoReparto.vue';
import IconoEstadoActivo from '@/views/Starkoms/Ordenes/IconoEstadoActivo.vue';
import IconoEstadoInactivo from '@/views/Starkoms/Ordenes/IconoEstadoInactivo.vue';
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";
import moment from 'moment-timezone'


export default defineComponent({
  name: "PublicTrackingOrder",
  components: {
    IconoEnPreparacion,
    IconoRetirado,
    IconoReparto,
    IconoEntregado,
    IconoEstadoInactivo,
    IconoEstadoActivo
  },
  methods:{
    momentWrapper(input){
      moment.locale('es');
      return moment(input);
    }
  },
  setup() {
    const axios: any = inject('axios')
    const shipping_number = ref('');
    const store_logo_url = ref('');
    const fecha_actualizacion = ref('');
    const orderHistory = ref([]);
    const carrier_name = ref('');
    const tracking_carrier = ref('');
    const order_id = ref('');
    const order_reference = ref('');
    const shipmentHistory = ref([]);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    //const { VUE_APP_API_URL } = inject('env');
    async function fetchData(){
        await axios({
            //url: VUE_APP_API_URL+'/shippings/tracking/'+route.params.hash,
            url: process.env.VUE_APP_API_URL+'/shippings/tracking/'+route.params.hash,
        }).then(response => {
            shipping_number.value = response.data.shipping_number;
            store_logo_url.value = response.data.store.logo_url;
            fecha_actualizacion.value = response.data.updated_at;
            orderHistory.value = response.data.order_history;
            carrier_name.value = response.data.carrier_name;
            tracking_carrier.value = response.data.tracking_carrier;
            order_id.value = response.data.order_id;
            order_reference.value = response.data.order_reference;
            shipmentHistory.value = response.data.shipment_history;
        }).catch( error => {
            console.log(error);
            router.push({name: '404'});
        })
    }
    watch(() => route, () => fetchData)
    onMounted(() => {
        fetchData();
      //store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      //store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });

    return {
        shipping_number,
        fecha_actualizacion,
        orderHistory,
        carrier_name,
        order_id,
        order_reference,
        shipmentHistory,
        store_logo_url,
        tracking_carrier,
    }
  },
});
